<template>
    <div class="float-left pt-3" style="width:100%;">
        <!-- <CCard>
          <CCardBody>
            <div class="col-12 float-left text-center p-3">
                <select style="width:200px;margin-left:auto;margin-right:auto;" @change="getOrders()" class="form-control" v-model="filter">
                    <option value="All">All</option>
                    <option value="needs_entered">Needs Entered</option>
                    <option value="closed">Closed</option>
                </select>
            </div>
            <table class="table" v-if="data">
                <thead>
                    <tr>
                        <th>Order#</th>
                        <th>Cust#</th>
                        <th>PO</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="value in data" @click="$router.push('/order_display/' + value.token)">
                        <td>{{value.id}}</td>
                        <td>{{value.customer_number}}</td>
                        <td>{{value.po_number}}</td>
                        <td>{{value.user_name}}</td>
                        <td>{{value.email}}</td>
                    </tr>
                </tbody>
            </table>
          </CCardBody>
        </CCard> -->
        <CCard>
          <CCardBody v-if="data">
            <CDataTable
              :items="data"
              :fields="fields"
              column-filter
              table-filter
              items-per-page-select
              :items-per-page="25"
              hover
              sorter
              pagination
            >
              
            </CDataTable>
          </CCardBody>
        </CCard>
    </div>
</template>
<script>
var methods = {}

methods.getOrders = function () {
    const postData = {
        filter: this.filter
    }
    axios.post(this.$apiAdress + '/api/getOrders?token=' + window.localStorage.getItem('api_token'), postData).then(response => {
        if (response.status === 200) {
            console.log(response)
            this.data = response.data
        }
    })
}

export default {
    name: "OrdersTable",
    data: function () {
    	return {
            data: null,
            fields: ['id', 'customer_number', 'po_number', 'user_name', 'email'],
            filter: 'All'
    	}
    },
    mounted: function() {
        this.getOrders()
    },
    methods: methods,
    destroyed: function() {

    }
}
</script>
